<template>
  <AuthBox :wideBg="true">
    <div class="py-16">
      <h1 class="auth-title indigo--text text--darken-4 pl-3 mb-3">
        {{ $t('signUpContent.forgot_title') }}
      </h1>
      <form class="pl-3">
        <v-row>
          <v-col cols="12" class="pt-10 pb-1">
            <BaseFormGroup :name="$t('form.email')" :validator="$v.form.email">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.email')" />
                <v-text-field
                  v-model="form.email"
                  v-bind="attrs"
                  outlined
                  dense
                  class="rounded-lg"
                  :placeholder="$t('form.enter_email')"
                  @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
        </v-row>
      </form>
      <div class="pl-3 mt-10">
        <v-btn :disabled="!this.$v.form.$dirty" width="120px" class="mr-1 indigo white--text no-transform" @click="submit">
          {{ $t('form.send') }}
        </v-btn>
      </div>
      <div class="pl-4 mt-16">
        <router-link class="no-decoration" to="/sign-in">
          {{ $t('signUpContent.back_login') }}
        </router-link>
      </div>
    </div>
    <template>
      <v-dialog v-model="showAuthDialog" max-width="500">
        <v-card>
          <div class="pt-10">
            <v-img src="../../assets/images/mail-icon.png" class="dialog-image" />
          </div>
          <div class="text-center text-h5 font-weight-bold dialog-title">
            {{ $t('signUpContent.reset_top') }}
          </div>
          <div class="text-center pb-8 pt-3 px-5 dialog-title">
            {{ $t('signUpContent.reset_body') }}
          </div>
          <div class="text-center pb-8">
            <v-btn color="primary" class="dialog-button" @click="() => (this.showAuthDialog = false)">
              {{ $t('content.ok') }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </template>
  </AuthBox>
</template>

<script>
import AuthBox from '@/components/auth/AuthBox';
import { email, required } from 'vuelidate/lib/validators';

export default {
  name: 'AuthResetPassword',
  components: { AuthBox },
  validations: {
    form: {
      email: { email, required },
    },
  },
  data: () => ({
    form: {
      email: '',
    },
    showPassword: false,
    showAuthDialog: false,
  }),
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.form.$invalid) {
        this.$toast.error('Invalid email, please try again');
        return;
      } else {
        this.$store
          .dispatch('Auth/resetPassword', this.form)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.showAuthDialog = true;
          })
          .catch(err => this.$toast.error(err.message));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-title {
  color: #16325c;
}
.dialog-image {
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
.dialog-title {
  font-size: 15px;
  color: #16325c;
}
.dialog-button {
  width: 100px;
}
.no-transform {
  text-transform: none;
}
.no-decoration {
  text-decoration: none;
}
</style>
